<template>
    <div class="animated fadeIn">
        <b-card>
            <div slot="header">
                <img width="35" height="35" src="../../assets/workflow/workflow.png" />
                <strong style="margin-left: 5px">Workflow Report</strong>

                <b-button style="float: right" variant="warning" size="md" @click="refresh">Refresh report</b-button>
            </div>

            <ReportTable
                ref="workflowReportTable"
                v-model="workflowForm.schema"
                :workflowId="workflow.id"
                :executionId="executionId"
                v-if="fetchedAll"
            />
        </b-card>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import ReportTable from './Tables/ReportTable.vue'

import {fetchAll} from '@/shared/fetchAll.js'

export default {
    components: {
        Feedback,
        Loading,
        ReportTable,
    },
    data() {
        return {
            tabs: ['Workflow Settings'],
            tabIndex: 0,
            workflowForm: {
                name: '',
                schema: {},
            },
            fetchedAll: false,
        }
    },
    computed: {
        ...mapGetters({
            pipelines: 'pipeline/pipelines',
            activeProject: 'project/active',
        }),
        tabOpen() {
            return this.tabs.map((tab, index) => (index === this.tabIndex ? true : false))
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Preparing the Workflow Visualization...`)
        this.executionId = this.$route.params.id
        await this.fetchWorkflow()
        await fetchAll(this.$store, this.activeProject.id)
        this.$store.commit('loading/PROCESSED')
        this.fetchedAll = true
    },
    methods: {
        async fetchWorkflow() {
            const workflowResponse = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/workflow/${this.$route.query.workflowId}`
            )

            this.workflow = workflowResponse.data.data
            this.workflowForm.name = this.workflow.name
        },

        refresh() {
            const ref = this.$refs[`workflowReportTable`]

            ref.refresh()
        },
    },
}
</script>

<style lang="scss">
.switch {
    margin-top: -3px;
}
</style>
