<template>
    <div class="workflow-report-table__wrapper">
        <wit-table
            class="workflow-report-table-summary"
            mode="server"
            :request-function="workflowRequestFunction"
            :fields="['workflowRunDate', 'resourceName', 'jobStatus', 'errors']"
            striped
            ref="workflowReportTable"
            track-by="name"
        >
            <template v-slot:cell(workflowRunDate)="{item}"> {{ item.workflowRunDate.value }} </template>
            <template v-slot:cell(jobStatus)="{item}">
                <b-spinner
                    v-if="item.jobState === 'PROCESSING'"
                    class="spinner-running"
                    sm
                    label="Spinning"
                    variant="primary"
                ></b-spinner>

                <i
                    v-else-if="item.jobState === 'FINISHED' && item.jobStatus === 'SUCCESS'"
                    v-b-tooltip.hover
                    title="Success"
                    class="icon fa fa-check success"
                />

                <i
                    v-else-if="
                        item.jobState === 'FINISHED' &&
                        item.jobStatus === 'FAILED' &&
                        !errorMessages(item.errors).length
                    "
                    v-b-tooltip.hover
                    :title="'Failed and retrying'"
                    class="icon fa fa-refresh warning"
                />

                <i
                    v-else-if="item.jobState === 'FINISHED' && item.jobStatus === 'FAILED'"
                    v-b-tooltip.hover
                    title="Failed"
                    class="icon fa fa-times failed"
                />
            </template>
            <template v-slot:cell(errors)="{item}">
                <span v-if="errorMessages(item.errors).length" v-b-tooltip.hover title="Show Errors">
                    <i class="actions fa fa-exclamation-triangle" @click="showErrorModal(item)" />
                </span>
            </template>
        </wit-table>

        <div v-if="workflowJobStatus !== 'SUCCEEDED' && workflowJobStatus !== 'FAILED'" class="text-center">
            <span>Workflow is running... </span>
            <b-spinner class="spinner-running" sm label="Spinning" variant="primary"></b-spinner>
        </div>

        <WitModal v-model="workflowErrorsModal.show" title="Workflow Errors" variant="danger" size="lg">
            <wit-table
                :items="workflowErrorsModal.messages"
                :mode="errorsTable.mode"
                :fields="errorsTable.fields"
                striped
                paginated
                hide-per-page-selector
                :ref="errorsTable.ref"
                :default-per-page="errorsTable.perPage"
                :class="errorsTable.class"
            ></wit-table>
        </WitModal>
    </div>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    name: 'report-table',
    components: {
        WitTable,
        WitModal,
    },
    data: () => {
        return {
            workflowErrorsModal: {
                show: false,
                messages: [],
            },
            workflowJobStatus: null,
            errorsTable: {
                ref: 'workflowErrorsTable',
                class: 'report-list-table',
                mode: 'client',
                fields: ['Error message'],
                perPage: 10,
            },
        }
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        workflowId: {
            type: String,
            required: true,
        },
        executionId: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.reloadInterval = setInterval(this.reloadTable, 5000)
    },
    beforeDestroy() {
        clearInterval(this.reloadInterval)
    },
    methods: {
        async workflowRequestFunction() {
            try {
                const query = `?workflowId=${this.workflowId}&executionId=${this.executionId}`
                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/workflow/executionReport${query}`
                )

                const data = response.data.data.report
                const status = response.data.data.status

                this.workflowJobStatus = status

                return {count: data.length, data, status}
            } catch (e) {
                console.error(e)
                this.fetchTimeout = setTimeout(this.fetchWorkflowJob, 5000)
            }
        },

        refresh() {
            this.$refs.workflowReportTable.fetchData()
        },

        reloadTable() {
            if (!document.hidden && this.$refs.workflowReportTable)
                this.$refs.workflowReportTable.fetchData({silent: true})
        },

        errorMessages(errors) {
            const messages = errors.map(({errorMsg}) => errorMsg).filter(Boolean)

            return messages
        },

        showErrorModal(item) {
            this.workflowErrorsModal.show = true
            this.workflowErrorsModal.messages = item.errors.map((msg) => {
                return {
                    'Error message': msg.errorMsg,
                }
            })
        },
    },
}
</script>

<style lang="scss">
.icon {
    font-size: 1.1rem;
}
.success {
    color: green;
}
.warning {
    color: #ffc32b;
}
.failed {
    color: red;
}

.workflow-report-success-thumb {
    padding-left: 1.2rem;
    .success-thumb-up {
        color: green;
    }
    .success-thumb-down {
        color: red;
    }
}

.spinner-running {
    margin-left: 5px;
    height: 0.875rem;
    width: 0.875rem;
    color: black !important;
    border-width: 0.2em;
}
</style>
